@tailwind base;
@tailwind components;
@tailwind utilities;

/*** Alpine JS utils ***/
[x-cloak] {
  display: none !important;
}

.no-scroll {
  @apply overflow-hidden;
}

:root {
  --svg-chevron-right-primary: url('data:image/svg+xml,%3Csvg%20width%3D%228%22%20height%3D%2214%22%20viewBox%3D%220%200%208%2014%22%20fill%3D%22none%22%20xmlns%3D%22http%3A%2F%2Fwww.w3.org%2F2000%2Fsvg%22%3E%3Cpath%20d%3D%22M1%2013L7%207L0.999999%201%22%20stroke%3D%22%23283A50%22%20stroke-width%3D%222%22%20stroke-linecap%3D%22round%22%20stroke-linejoin%3D%22round%22%2F%3E%3C%2Fsvg%3E');
  --svg-chevron-right-white: url('data:image/svg+xml,%3Csvg%20width%3D%228%22%20height%3D%2214%22%20viewBox%3D%220%200%208%2014%22%20fill%3D%22none%22%20xmlns%3D%22http%3A%2F%2Fwww.w3.org%2F2000%2Fsvg%22%3E%3Cpath%20d%3D%22M1%2013L7%207L0.999999%201%22%20stroke%3D%22%23FFFFFF%22%20stroke-width%3D%222%22%20stroke-linecap%3D%22round%22%20stroke-linejoin%3D%22round%22%2F%3E%3C%2Fsvg%3E');
  --svg-arrow-down-primary: url('data:image/svg+xml,%3Csvg%20width%3D%2224%22%20height%3D%2225%22%20viewBox%3D%220%200%2024%2025%22%20fill%3D%22none%22%20xmlns%3D%22http%3A%2F%2Fwww.w3.org%2F2000%2Fsvg%22%3E%3Cpath%20d%3D%22M1.5%202L12%2012.5L22.5%202%22%20stroke%3D%22%23283A50%22%20stroke-width%3D%222.5%22%20stroke-linecap%3D%22round%22%20stroke-linejoin%3D%22round%22%2F%3E%3Cpath%20d%3D%22M1.5%2012.5L12%2023L22.5%2012.5%22%20stroke%3D%22%23283A50%22%20stroke-width%3D%222.5%22%20stroke-linecap%3D%22round%22%20stroke-linejoin%3D%22round%22%2F%3E%3C%2Fsvg%3E');
}

body {
  @apply bg-white-smoke font-sans leading-[normal] m-0;
}

*,
::before,
::after {
  border-width: 0;
  box-sizing: border-box;
}

@property --num {
  syntax: '<integer>';
  initial-value: 0;
  inherits: false;
}

.sticky {
  position: -webkit-sticky;
  position: sticky;
  top: 0;
  /* z-index: 10; */
}

/** CookieYes **/
body .cky-consent-container,
body .cky-consent-container.cky-box-bottom-left {
  /* bottom: 50%;
  left: calc(50% - 220px); */
  position: fixed;
  top: 30%;
  left: 50%;
  transform: translate(-50%, -30%);
  z-index: 999999999;
}

body .cky-consent-container::before,
body .cky-consent-container.cky-box-bottom-left::before {
  content: '';
  display: block;
  position: fixed;
  left: 0;
  top: 0;
  transform: translate(-50%, -30%);
  z-index: -1;
  width: 200vw;
  height: 200vh;
  overflow: hidden;
  background: #00000070;
}

@media all and (max-width:440px) {
  body .cky-consent-container.cky-box-bottom-left {
    left: 0;
  }
}

body .cky-consent-container.cky-box-bottom-left .cky-preference-center .cky-prefrence-btn-wrapper button.cky-btn.cky-btn-reject {
  display: none;
}

.header-main {
  @apply fixed top-0 z-999 w-full bg-white-smoke;

  & .menu-wrapper {
    @apply order-3 md:order-2 w-full md:w-fit transition-all duration-200;

    & .menu-main {
  
      & ul {
        @apply flex flex-col md:flex-row md:gap-x-6 flex-wrap md:flex-nowrap items-start md:items-center my-0 pl-0 list-none;
  
        &>li {
          &>a {
            @apply flex flex-col flex-nowrap items-center justify-center p-2 md:p-2.5 text-primary text-lg font-semibold no-underline;
  
            &:after {
              @apply content-[''] block w-0 h-0.5 mt-1 md:mt-2 bg-primary transition-all duration-500 self-start;
            }
  
            &:hover {
              @apply no-underline;
            }
  
            &:hover:after {
              @apply w-full transition-all duration-500;
            }
          }
        }
  
        &>li.menu-cta {
          &>a {
            @apply px-6 py-3 font-bold;
  
            &:after {
              @apply w-0 h-0 m-0 bg-transparent;
            }
  
            &:hover {
              @apply text-white;
            }
            &:hover:after {
              @apply m-0 w-0 h-0;
            }
          }
        }
      }
    }
  }


  & .menu-burger {
    @apply block md:hidden text-primary;
  }
}

@layer base {

  @font-face {
    font-family: 'Noto Sans';
    src: url('/public/fonts/NotoSans.woff2') format('woff2'),
      url('/public/fonts/NotoSans.woff') format('woff');
    font-weight: 1 999;
    font-style: normal;
    font-display: block;
  }

  @font-face {
    font-family: 'Noto Sans';
    src: url('/public/fonts/NotoSans-Italic.woff2') format('woff2'),
      url('/public/fonts/NotoSans-Italic.woff') format('woff');
    font-weight: 1 999;
    font-style: italic;
    font-display: block;
  }

  h1,
  .h1 {
    @apply my-0 text-primary text-4xl md:text-5xl lg:text-6xl xl:text-h1 leading-tight font-bold;
  }

  h2,
  .h2 {
    @apply my-0 text-primary text-3xl md:text-h2 leading-none font-bold tracking-tight;
  }

  h3,
  .h3 {
    @apply my-0 text-primary text-2xl md:text-h3 leading-snug font-bold tracking-tight;
  }

  h4,
  .h4 {
    @apply my-0 text-primary text-base md:text-h4 leading-normal font-bold;
  }

  p {
    @apply text-base leading-normal;

    & strong {
      @apply font-semibold;
    }
  }

  .perex {
    @apply text-primary text-base md:text-2xl leading-normal font-normal;
  }
  
}

@layer components {

  .section-cta {
    @apply px-6 sm:px-8 py-12 sm:py-16;
  }

  .row-medium {
    @apply w-full max-w-screen-lg mx-auto px-8 md:px-8 py-4 sm:py-8;
  }

  .row-wide {
    @apply w-full max-w-screen-xl mx-auto px-8 py-4 sm:py-8;
  }

  .row-wider {
    @apply w-full max-w-[90rem] mx-auto px-8 py-4 sm:py-8;
  }

  .row-full {
    @apply w-full max-w-full mx-auto px-8 py-4 sm:py-8;
  }

  .button-primary {
    @apply flex flex-row flex-nowrap items-center gap-x-3 w-fit h-fit bg-yellow rounded-4xl px-6 py-4 text-primary text-base md:text-lg leading-none font-semibold tracking-wide no-underline cursor-pointer transition-all duration-200;

    &:after {
      background-image: var(--svg-chevron-right-primary);
      @apply content-[''] block w-3 h-4 bg-contain bg-no-repeat bg-center transition-all duration-200;
    }

    &:hover {
      @apply text-white no-underline transition-all duration-200;
    }

    &:hover:after {
      background-image: var(--svg-chevron-right-white);
      @apply transition-all duration-200;
    }
  }

  .button-more {
    background-image: var(--svg-arrow-down-primary);
    @apply flex flex-col w-5 h-5 bg-no-repeat bg-contain bg-center;
  }

  .blurb-primary {
    @apply flex flex-row flex-nowrap gap-2 md:gap-4 items-start justify-start w-fit max-w-full py-2;

    & .blurb-img {
      @apply w-6 h-auto md:max-h-6 aspect-square;
    }

    & .blurb-text,
    & .blurb-text a {
      @apply text-primary text-base font-semibold leading-normal no-underline;

      &:hover {
        @apply no-underline;
      }
    }
  }

  .sluzby-list {
    @apply flex flex-col gap-y-4 md:gap-y-8;
  }

  .sluzba {
    @apply flex flex-row flex-nowrap gap-2 mb-6 md:mb-0 border-b border-solid border-transparent pb-3 md:pb-6 text-white cursor-pointer transition-all duration-200;

    &>span {
      @apply block h-fit text-netion-grey text-2xl md:text-3xl font-semibold uppercase;
    }
    
    &>h3 {
      @apply m-0 mt-2 text-netion-grey text-2xl md:text-3xl font-semibold leading-tight;
    }

    &.active {
      @apply border-white transition-all duration-200;

      &>span {
        @apply text-yellow transition-all duration-200;
      }

      &>h3 {
        @apply text-white transition-all duration-200;
      }
    }

    &:hover {
      &>span {
        @apply text-yellow transition-all duration-200;
      }

      &>h3 {
        @apply text-white transition-all duration-200;
      }
    }
  }

  .sluzba-detail {
    @apply flex flex-col gap-y-4 md:gap-y-8;

    & .sluzba-tagy {
      @apply flex flex-row flex-wrap gap-3 md:gap-5 text-white;

      &>span {
        @apply block w-fit border border-solid border-white rounded-10 px-4 md:px-6 py-2 md:py-3 text-white text-sm md:text-xl font-bold;
      }
    }

    .sluzba-text {
      @apply text-sm md:text-base text-white;
    }
  }

  .projekty {

    & .projekt {
      @apply grid grid-cols-1 grid-rows-1;

      & .projekt-img {
        @apply relative z-10 col-span-full row-span-full w-full h-full;

        &>img {
          @apply relative z-1 block w-full h-full object-cover object-center border-2 border-solid border-white rounded-img-sm md:rounded-img-lg;

        }
        &::after {
          @apply content-[''] z-2 absolute inset-0 block w-full h-full bg-[linear-gradient(180deg,rgba(3,3,3,0.50)-1.21%,rgba(255,255,255,0.00)29.55%)] bg-no-repeat bg-[center_top] bg-cover rounded-img-sm md:rounded-img-lg;
        }
      }

      & .projekt-content {
        @apply relative z-20 col-span-full row-span-full w-full h-fit grid grid-cols-2 grid-rows-1 items-center px-4 md:px-14 py-3 md:py-10;

        & .projekt-logo {
          @apply col-start-1 col-end-2 row-start-1 row-end-2 w-full md:max-w-72 h-fit;
          &>a {
            @apply block w-full bg-no-repeat bg-contain bg-center;
  
            & img {
              @apply block w-full;
  
              &:hover {
                /* filter: invert(17%) sepia(20%) saturate(1393%) hue-rotate(172deg) brightness(92%) contrast(83%); */
              }
            }
          }
  
        }
  
        & .projekt-text {
          @apply z-20 col-start-2 col-end-3 row-start-1 row-end-2 hidden md:flex flex-col items-end h-fit;
  
          &>h3 {
            @apply block w-fit h-fit m-0 px-6 py-3 rounded-10 bg-white text-primary text-xl font-bold text-center;
          }
        }
      }


      &.drevostavitel {
        & .projekt-logo a {
          @apply bg-[url('/public/assets/projekty/drevostavitel-cz-logo-white.svg')] w-24 h-4 md:w-64 md:h-10 transition-all duration-200;

          &:hover {
            @apply bg-[url('/public/assets/projekty/drevostavitel-cz-logo.svg')] transition-all duration-200;
          }
        }
      }

      &.archevio {
        & .projekt-logo a {
          @apply bg-[url('/public/assets/projekty/archevio-cz-logo-white.svg')] w-28 h-4 md:w-[19rem] md:h-11 transition-all duration-200;

          &:hover {
            @apply bg-[url('/public/assets/projekty/archevio-cz-logo.svg')] transition-all duration-200;
          }
        }
      }

      &.veleton {
        & .projekt-logo a {
          @apply bg-[url('/public/assets/projekty/veleton-cz-logo-white.svg')] w-20 h-6 md:w-48 md:h-14 transition-all duration-200;

          &:hover {
            @apply bg-[url('/public/assets/projekty/veleton-cz-logo.svg')] transition-all duration-200;
          }
        }
      }
    }
  }

  .vyhody {
    @apply flex flex-row flex-wrap md:flex-nowrap justify-between gap-10 md:gap-10 lg:gap-16 xl:gap-24;

    & .vyhoda {
      @apply max-w-64 md:max-w-96 px-4 md:px-8 py-4 md:py-12;

      & .vyhoda-title {
        @apply flex flex-row flex-nowrap items-center gap-4 md:block pb-4 md:pb-8 border-b border-solid border-black;

        & h3.h1 {
          @apply flex flex-row flex-nowrap gap-0 my-0 text-4xl md:text-5xl lg:text-6xl xl:text-h1 leading-none;

          &:after {
            content: '+';
            @apply self-center md:self-start block w-fit h-fit ml-1 md:ml-0 md:-mt-4 text-yellow text-2xl md:text-h1 leading-none font-bold;
          }
        }
        & h3.h2 {
          @apply my-0 text-2xl md:text-4xl lg:text-h2 leading-none;
        }
      }

      & .vyhoda-popis {
        @apply pt-8 md:pt-16 text-primary text-base md:text-2xl leading-normal md:leading-snug;

        & p {
          @apply m-0 p-0 text-base md:text-2xl leading-snug;
        }
      }
    }
  }

  .reference-swiper {
    @apply w-full max-w-full;

    & .swiper-wrapper {

      & .swiper-slide {
        @apply grid grid-cols-1 grid-rows-[auto_1fr] rounded-10;

        & .slide-img {
          @apply grid grid-cols-1 grid-rows-1 aspect-[2/1] rounded-10 overflow-hidden;

          & img {
           @apply col-start-1 col-end-2 row-start-1 row-end-2 z-1 flex w-full h-auto aspect-[2/1] rounded-10;
 
           /* &::before {
             @apply content-[''] 
           } */
         }

         & div.video {
          @apply col-start-1 col-end-2 row-start-1 row-end-2 z-2 w-full h-auto aspect-[2/1] rounded-10;

          & video {
            @apply opacity-100 w-full h-full aspect-[2/1] rounded-10;
          }
         }
        }

        & .slide-content {
          @apply bg-white rounded-b-10 p-4 md:p-8 text-primary leading-normal transition-all duration-300;

          & h4,
          & div {
            @apply translate-y-0 transition-transform duration-200;
          }

          & h4 {
            @apply mb-2 md:mb-4;
          }
        }

        &:not(.swiper-slide-active) {
          & .slide-img .video video {
            @apply opacity-0;
          }
        }

        &:not(.swiper-slide-active) .slide-content {
          @apply flex flex-col h-0 overflow-hidden -mt-16 bg-transparent rounded-10 transition-all duration-300;

          & h4,
          & div {
            @apply translate-y-48 transition-transform duration-200;
          }
        }
      }
    }

    & .swiper-pagination {
      @apply z-20;
    }

    & .swiper-button-prev {
      @apply z-20;
    }

    & .swiper-button-next {
      @apply z-20;
    }
  }

  .reference-swiper .swiper-horizontal>.swiper-pagination-bullets,
  .reference-swiper .swiper-pagination-bullets.swiper-pagination-horizontal {
    @apply flex items-center justify-center;
  }

  .reference-swiper .swiper-horizontal>.swiper-pagination-bullets .swiper-pagination-bullet,
  .reference-swiper .swiper-pagination-horizontal.swiper-pagination-bullets .swiper-pagination-bullet {
    @apply mx-2 w-4 h-4 bg-darkgray opacity-100 focus:outline-0;
  }

  .reference-swiper .swiper-horizontal>.swiper-pagination-bullets .swiper-pagination-bullet.swiper-pagination-bullet-active,
  .reference-swiper .swiper-pagination-horizontal.swiper-pagination-bullets .swiper-pagination-bullet.swiper-pagination-bullet-active {
    @apply w-6 h-6 bg-yellow opacity-100;
  }

  .reference-swiper .swiper-pagination {
    --swiper-pagination-top: -4px;
    @apply z-50 h-full;
  }

  .reference-swiper .swiper-pagination,
  .reference-swiper .swiper-button-next,
  .reference-swiper .swiper-button-prev {
    @apply relative inset-0 mt-0;
  }

  .reference-swiper .swiper-button-next::after, 
  .reference-swiper .swiper-button-prev::after {
    display: none;
  }

  .reference-swiper .swiper-button-next::before,
  .reference-swiper .swiper-button-prev::before {
    @apply flex items-center justify-center w-6 h-6 md:w-8 md:h-8;
  }

  .reference-swiper .swiper-button-next::before {
    content: url('/public/assets/icons/ico-arrow-right.svg');
  }

  .reference-swiper .swiper-button-prev::before {
    content: url('/public/assets/icons/ico-arrow-left.svg');
  }

  .logo-carousel {
    @apply flex items-center justify-center md:justify-start w-full max-w-full overflow-x-auto list-none ;

    & .carousel-item {
      @apply inline-flex items-center justify-center w-40 md:w-64 h-20 md:h-32 mx-4 md:mx-8;

      & img {
        @apply max-w-none;
      }
    }
  }

  .form-contact {
    @apply text-black;

    & h3 {
      @apply text-black font-normal md:text-2xl leading-normal;
    }

    & input,
    & textarea {
      @apply resize-none box-border border border-solid border-primary rounded-10 p-4 text-primary text-base;

      &::placeholder {
        @apply text-netion-grey;
      }

      &:focus {
        @apply border-primary-900 outline-1 outline-primary-900 outline-offset-0;
      }
    }

    & textarea {
      @apply md:min-h-full h-full;
    }

    & input:checked+svg {
      display: block;
    }

    & label {
      @apply text-black text-base font-normal;
    }

    & input[type='checkbox'],
    & input[type='checkbox']+label,
    & input[type='radio'],
    & input[type='radio']+label {
      @apply cursor-pointer
    }
  }

}

@layer utilities {
  .bg-image-dark {
    background-image: url('/public/assets/shape-dark.svg');
    @apply bg-contain bg-no-repeat bg-center bg-fixed opacity-10;
  }

  .bg-image-light {
    @apply overflow-hidden;
    
    & .row-wide {
      @apply translate-y-0 transition-all duration-700;
    }

    &::before {
      transition: background-position 750ms ease-in-out;
      @apply content-[''] absolute inset-auto z-0 block w-full h-full min-w-full min-h-full md:bg-[url('/public/assets/shape-white.svg')] bg-[auto_60%] bg-no-repeat bg-fixed bg-[120%_220%] opacity-5;
    }

    &.loaded-bg {
      &::before {
        @apply bg-[120%_100%];
      }
    }

    &.animate-bg .row-wide {
      @apply translate-y-[100%] transition-all duration-300;
      /* &::before {
        @apply bg-[120%_220%];
      } */
    }
  }

  /* .bg-image-light {
    background-image: url('/public/assets/shape-white.svg');
    @apply bg-[auto_60%] bg-no-repeat bg-fixed bg-[120%_100%] bg-blend-screen;
  } */

  .rounded-img {
    @apply rounded-tl-100 rounded-tr-5 rounded-br-100 rounded-bl-5;
  }

  .rounded-img-lg {
    @apply rounded-tl-100 rounded-tr-10 rounded-br-100 rounded-bl-10;
  }

  .rounded-img-sm {
    @apply rounded-tl-25 rounded-tr-4 rounded-br-25 rounded-bl-4;
  }

}